var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { AssistCaseService } from "~/services/business-service/assist-case.service";
import { CollectionData } from "~/config/types/collection-data";
var collectionManageModule = namespace("collection-manage");
var TelephoneCollection = /** @class */ (function (_super) {
    __extends(TelephoneCollection, _super);
    function TelephoneCollection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.caseId = "";
        _this.assistOutType = "";
        _this.assistFlag = "OFFSITE_PHONE_ASSIST";
        _this.queryParamsModel = {
            principalId: "",
            batchNumber: "",
            personalName: "",
            assistStatus: "",
            certificateNo: "",
            cardNo: "",
            phone: "",
            collectorName: "",
            assistCollector: "",
        };
        _this.selectionList = [];
        _this.dataSet = [];
        _this.loading = {
            state: false,
        };
        _this.rules = {
            idCard: [{ min: 4, message: "请输入至少4位的身份证号", trigger: "blur" }],
        };
        return _this;
    }
    TelephoneCollection.prototype.cellStyle = function (_a) {
        var row = _a.row;
        if (!row.color)
            return;
        return { color: row.color + " !important" };
    };
    TelephoneCollection.prototype.routeChange = function (val) {
        if (!val)
            return;
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
    };
    TelephoneCollection.prototype.refreshData = function () {
        this.loading.state = true;
        // this.assistCaseService.queryAssistCases(this.queryParamsModel,this.pageService,this.sortService,this.loading)
        //   .subscribe((data) => {
        //     this.dataSet = data;
        //   });
    };
    TelephoneCollection.prototype.onBatchNumberClick = function (data) {
        var collectionData = new CollectionData(this.queryParamsModel.principalId, data.caseId, this.queryParamsModel);
        this.openExecutionPage(collectionData);
    };
    TelephoneCollection.prototype.onEnd = function (data) {
        var _this = this;
        this.$confirm("您确定结束协调吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            var assistId = data.assistId;
            _this.loading.state = true;
            // this.assistCaseService.endAssistCaseCollect(assistId, this.loading)
            //   .subscribe((data) => {
            //     this.$message.success('结束协调成功!'),
            //     this.refreshData();
            //   });
        })
            .catch(function () { });
    };
    __decorate([
        Dependencies(PageService)
    ], TelephoneCollection.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], TelephoneCollection.prototype, "sortService", void 0);
    __decorate([
        Dependencies(AssistCaseService)
    ], TelephoneCollection.prototype, "assistCaseService", void 0);
    __decorate([
        collectionManageModule.Action
    ], TelephoneCollection.prototype, "openExecutionPage", void 0);
    __decorate([
        Watch("$route", { immediate: true })
    ], TelephoneCollection.prototype, "routeChange", null);
    TelephoneCollection = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
            },
        })
    ], TelephoneCollection);
    return TelephoneCollection;
}(Vue));
export default TelephoneCollection;
